.subHeader {
  display: flex;
  flex-direction: row;
  align-content: center;
  /*align-items: center;*/
  justify-content: space-between;
}

.addInput {
  display: inline-flex;
  align-self: flex-end;
}

.addButton {
  background-color: #039be5 !important;
  color: #ffffff !important;
  box-shadow: 0 1px 6px 0px rgb(0 0 0 / 24%), 0 2px 4px 0px rgb(0 0 0 / 24%) !important;
  z-index: 99 !important;
}
.addScenariosAutoButton {
  background-color: #039be5 !important;
  color: #ffffff !important;
  box-shadow: 0 1px 6px 0px rgb(0 0 0 / 24%), 0 2px 4px 0px rgb(0 0 0 / 24%) !important;
  z-index: 99 !important;
  border-radius: 50px !important;
  margin-right: 10px !important;
}

.updateToButton {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  background: #039be5;
  border-radius: 50px;
  height: 24px;
  padding: 0px 15px 0px 13px;
  color: #fff;
}

.no-padding {
  padding: 0;
}

.ReactTable {
  width: 100%;
  border-radius: 6px;
  border: none;
  justify-content: left;
  box-shadow: 0 20px 25px 0px rgb(0 0 0 / 0.2), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

/*.rt-table {

}*/

.rt-thead {
  border-bottom: 2px solid #047bb7;
  background-color: #039be5;
  color: #fafafa;
  font-size: 16px;
  border-radius: 8px 8px 0 0;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  padding: 12px;
  font-weight: 700;
  letter-spacing: 0.025em;
  justify-content: left;
  text-align: left;
}

.ReactTable .rt-td {
  padding: 4px 14px;
  white-space: normal;
  display: flex;
}

.main-badge {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.form-ir {
  display: flex;
}

.filterForm {
  width: 300px;
  padding-right: 5px !important;
}

.ReactTable .rt-thead .-header {
  min-width: 200px;
}

.ReactTable .rt-body {
  min-width: 200px !important;
}

.toggleButton {
  display: none;
}
.accounts {
  padding-top: 60px;
}

.widgetContentToolbar {
  display: flex;
  justify-content: flex-end;
}

.widgetContentToolbar div div div.toggleButton span span {
  color: #ffffff;
}

div.grid-menu {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  transition: 1s ease;
  padding: 10px 3px;
}

input#input-with-icon-textfield {
  width: 400px;
  height: 50px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  padding-left: 15px;
}

.floatingLabel {
  color: '#cc0000';
}

.MuiTableCell-root {
  padding: 5px;
}

/* Increase the specificity */
.MenuItem.Mui-selected {
  color: blue;
}

.MuiInputLabel.Mui-focused {
  font-size: larger;
}

.inputSearch div > span {
  color: #0284c7;
}

.inputSearch {
  margin-right: 20px;
}

.inputSearch label {
  color: #0284c7;
  padding: 16px 0 10px 15px;
  font-size: medium;
  display: flex;
  z-index: 10;
}

.select label {
  color: #0284c7;
  font-size: medium;
  display: flex;
  z-index: 10;
}
button:hover {
  background-color: #4fc6ff;
}

.iconSearch {
  margin-top: -4px;
}

.labelSearch {
  position: relative;
  top: -5px;
  left: 4px;
}

.inputCheckbox {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 14px;
}

.addRoomButton {
  background-color: #2d323e;
  color: #ffffff;
  border-radius: 6px;
  width: 180px;
  height: 180px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 5;
}

.addRoomButton a,
.addRoomButton a > p {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  padding: 3px;
}

.roomsButtons {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  gap: 15px;
  margin: 0 auto;
  max-width: 1200px;
}
.roomsButtons div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 15px; */
}

.roomButtom {
  background: #2d323e;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-areas:
    'room room'
    'edit devices'
    'ordem ordem';
  gap: 5px;
  width: 180px;
  height: 180px;
  margin: 5;
}

#room {
  grid-area: room;
  width: 180px;
  height: 50px;
  align-self: center;
  justify-content: center;
  padding-top: 20px;
}

#room p {
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
}

#edit {
  grid-area: edit;
  height: 60px;
  width: 60px;
  align-self: start;
  justify-content: end;
  padding: 3px;
}
#devices {
  grid-area: devices;
  height: 60px;
  width: 60px;
  align-self: start;
  justify-content: center;
  padding: 3px;
}
#ordem {
  grid-area: ordem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 20px;
}
#ordem div div input {
  width: 50px;
  border-radius: 50px;
  background-color: #4a5061;
  color: #fff;
  padding: 3px 8px;
}

#ordem div div::before {
  content: none;
}

.createCheckboxAllTypesDevices {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-right: 30px;
  background: #eaeaea;
  border-radius: 50px;
  height: 50px;
  padding-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.createCheckboxAllTypesDevices-container {
  display: flex;
  align-content: center;
  justify-self: start;
  flex-direction: row;
}

.content-padding {
  padding: 0 2.4em;
}

.updateButton {
  position: relative !important;
  font-size: 16px !important;
  background-color: #039be5 !important;
  color: #ffffff !important;
  border-radius: 50px !important;
  box-shadow: 0 1px 6px 0px rgb(0 0 0 / 24%), 0 2px 4px 0px rgb(0 0 0 / 24%) !important;
}

.expansionConteiner {
  flex-direction: column;
}

.logs-numbers {
  margin: 20px 0;
}

.logs-buttons {
  display: flex;
  justify-content: space-evenly;
}
div.login-input {
  margin: 12px 0;
}

/* Cards  */
@media screen and (max-width: 748px) {
  .content-padding {
    padding: 0 15px;
  }

  .subHeader {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 99;
  }

  .accounts {
    padding-top: 0px;
  }

  .createCheckboxAllTypesDevices {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-grow: 1;
  }
  .createCheckboxAllTypesDevices-container {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-grow: 3;
  }

  .inputCheckbox {
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 0px;
  }

  .inputSearch {
    margin-right: 0px;
  }

  .addButton,
  a.addButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #039be5 !important;
    color: #ffffff !important;
    box-shadow: 0 1px 6px 0px rgb(0 0 0 / 10%), 0 2px 4px 0px rgb(0 0 0 / 10%);
    z-index: 99;
  }

  .updateButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #039be5;
    color: #ffffff;
    border-radius: 50px;
    box-shadow: 0 1px 6px 0px rgb(0 0 0 / 10%), 0 2px 4px 0px rgb(0 0 0 / 10%);
    z-index: 99;
  }

  input#input-with-icon-textfield {
    width: 100%;
    height: 40px;
  }

  .inputSearch label {
    color: #0284c7;
    padding: 12px 0 10px 15px;
    font-size: small;
    display: flex;
    z-index: 10;
  }

  .widgetContentToolbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  }

  .widgetContentToolbar .dark {
    background-color: #2d323e;
  }
  .widgetContentToolbar .light {
    background-color: #ffffff;
  }

  .light div div div.grid-menu {
    background-color: #fefefe;
    box-shadow: inset 0 1px 15px 0 rgb(0 0 0 / 0.1),
      inset 0 1px 15px -1px rgb(0 0 0 / 0.1);
  }
  .dark div div div.grid-menu {
    box-shadow: inset 0 1px 15px 0 rgb(0 0 0 / 0.6),
      inset 0 1px 15px -1px rgb(0 0 0 / 0.5);
  }

  .dark div div div.toggleButton span span {
    color: #ffffff;
  }
  .light div div div.toggleButton span span {
    color: #039be5;
  }

  .widgetOption {
    display: block;
    justify-content: end;
    overflow-y: hidden;
    height: 35px;
    transition: 0.3s ease;
  }

  .widgetOption div div {
    background: #2d323e;
    border-radius: 50px;
  }
  .light div div div.grid-menu div div {
    background-color: #039be5;
  }
  .toggleButton {
    display: flex;
    justify-content: flex-end;
    transition: 1s ease;
  }

  div.grid-menu {
    display: flex;
    justify-content: space-around;
    align-content: center;
    transition: 1s ease;
    padding: 10px 3px;
    border-radius: 6px;
    /*      box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 0.6), inset 0 1px 2px -1px rgb(0 0 0 / 0.6);*/
  }

  .active {
    display: flex;
    background: #4a5061;
  }

  /* Cards badges */
  .blue-badge {
    padding: 4px 10px;
    color: #0284c7;
    background-color: #f0f9ff;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  /* deletar */
  .updated-badge {
    padding: 4px 10px;
    color: #333333;
    background-color: #fafafa;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  /* deletar */
  .deleted-badge {
    padding: 4px 10px;
    color: #5f4646;
    background-color: #fafafa;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  .white-badge {
    padding: 4px 10px;
    color: #333333;
    background-color: #fafafa;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    white-space: normal;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  .green-badge {
    padding: 4px 10px;
    color: #10b981;
    background-color: #dcfce7;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    white-space: normal;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  .red-badge {
    padding: 4px 10px;
    color: #f87171;
    background-color: #fef2f2;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    white-space: normal;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  .gray-badge {
    padding: 4px 10px;
    color: #aeaeae;
    background-color: #f1f1f1;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    text-transform: uppercase;
    white-space: normal;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  .addRoomButton {
    width: 140px;
    height: 140px;
  }
  .addRoomButton div a p {
    font-size: 10px;
  }

  .roomsButtons {
    grid-template-columns: repeat(3, 1fr);
  }

  /* .roomsButtons div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 0px;
  } */

  .roomButtom {
    /* display: flex;
    justify-content: center; */
    height: 140px;
    width: 140px;
  }

  #room {
    grid-area: room;
    width: 110px;
    align-self: end;
    justify-content: center;
    word-wrap: normal;
    padding-top: 10px;
  }

  #room p {
    font-size: 12px;
    padding: 0 10px;
  }

  #edit {
    grid-area: edit;
    text-align: center;
    height: 40px;
    width: 40px;
    align-self: start;
    justify-content: center;
    padding: 0 0 10px 25px;
  }

  #devices {
    grid-area: devices;
    height: 40px;
    width: 40px;
    align-self: start;
    justify-content: center;
    padding: 0 0 10px 0;
  }

  #ordem {
    grid-area: ordem;
    display: flex;
    justify-content: center;
    height: 40px;
    bottom: 10px;
  }

  .expansionConteiner div {
    flex-direction: column;
  }
  .main-badge > span.checkbox-update {
    padding: 5px;
  }

  div.expansionConteiner > div > .cards-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.create-scenarios-comands-list {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 6%),
    0px 2px 1px -1px rgb(0 0 0 / 6%);
  align-items: center;
  margin-bottom: 5px;
  height: 40px;
  border-radius: 4px;
  max-width: 400px;
}

.create-scenarios-comands-list-item {
  padding: 8px 12px;
  display: flex;
}

.create-scenarios-comands-list-item-name {
  display: flex;
  justify-self: start;
  padding-left: 10px;
}

@media screen and (max-width: 640px) {
  .p-24 {
    padding: 15px;
  }

  .ReactTable {
    border-radius: 6px;
    border: none;
    justify-content: left;
    box-shadow: none;
  }

  .rt-tbody {
    padding: 3px;
  }

  .ReactTable .rt-thead {
    display: none;
  }

  .ReactTable .rt-thead .-header {
    min-width: 200px !important;
  }

  .ReactTable .rt-thead .rt-resizable-header-content {
    padding: 4px 8px;
    font-weight: 700;
    justify-content: left;
    text-align: left;
    display: none;
  }

  .ReactTable .rt-tr-group {
    box-shadow: 0 1px 6px 0px rgb(0 0 0 / 0.1), 0 2px 4px 0px rgb(0 0 0 / 0.1);
    margin: 5px 0px;
    border-radius: 5px;
    background-color: white;
  }

  .ReactTable .rt-tr {
    /*flex: 1 1 auto;*/
    display: flex;
    flex-direction: column;
    /*grid-template-columns: auto 65px;*/
    align-items: flex-start;
    padding: 15px 0;
  }

  .ReactTable .rt-td {
    padding: 4px 14px;
    overflow: visible;
    display: flex;
  }

  .ReactTable .rt-tr > .cardsButtons {
    order: -1;
    display: flex;
    justify-content: flex-end;
    margin-top: -15px;
    margin-bottom: -30px;
    width: 100%;
  }

  .cardButton {
    margin-right: -10px;
  }

  /* firmware updates MUI Cards */
  div.expansionConteiner > div > .cards-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -30px;
  }

  .ver-logs {
    font-size: 14px;
  }

  /* PAGINAÇÂO */
  .ReactTable .-pagination {
    box-shadow: none;
    border: 0;
  }

  .ReactTable .-pagination .-previous,
  .ReactTable .-pagination .-next {
    text-align: left;
    max-width: none;
  }

  .ReactTable .-pagination .-next {
    flex: 2;
  }

  .ReactTable .-pagination .-center {
    flex: 6 1;
  }

  .ReactTable .-pagination .-previous > button,
  .ReactTable .-pagination .-next > button {
    padding: 12px 0;
  }

  .ReactTable .-pagination .-pageJump input {
    width: 40px;
    text-align: center;
  }

  .ReactTable .-pagination input,
  .ReactTable .-pagination select {
    width: 90px;
  }

  .expansionPanel {
    border-radius: 6px;
    background: #f5f5f5;
    margin: 16px 0;
  }

  .expansionPanel::before {
    display: none;
  }

  .expansionConteiner {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .scenarios-tabs div div div {
    flex-direction: column;
    align-content: space-between;
    gap: 5px;
  }
  .scenarios-tabs div div div button {
    flex: 1;
    background-color: #039be5;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    max-width: 100%;
  }

  .full-width-button {
    width: 100%;
    margin: 20px 0;
  }

  .addScenariosAutoButton {
    margin-top: 15px;
    margin-right: 0;
  }

  /* BADGES */

  .main-badge {
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .badge-spacer {
    margin-bottom: 10px;
  }
  .central-version-badge::before {
    content: 'Versão Central: ';
    font-size: 10px;
    color: #0284c7;
    text-transform: none;
  }

  .since-badge::before {
    content: 'Cliente desde: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .created-badge::before {
    content: 'Criação: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .version-badge::before {
    content: 'Versão: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .actual-version-badge::before {
    content: 'Versão atual: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .updated-version-badge::before {
    content: 'Versão atualizada: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .batch-badge::before {
    content: 'Lote: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .hardware-version-badge::before {
    content: 'Versão hardware: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .firmware-version-badge::before {
    content: 'Versão firmware: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .status-badge::before {
    content: 'Status: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .updated-badge::before {
    content: 'Atualização: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .updated-github-badge::before {
    content: 'Atualizado no Github: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
  }

  .deleted-badge::before {
    content: 'Exclusão: ';
    font-size: 10px;
    color: #5f4646;
    text-transform: none;
  }

  .mac-badge::before {
    content: 'Endereço MAC: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .ip-badge::before {
    content: 'IP: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .device-badge::before {
    content: 'Dispositivo: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .controle-badge::before {
    content: 'Controle: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .message-badge::before {
    content: 'Mensagem: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .origem-badge::before {
    content: 'Origem: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .recebido-api-badge::before {
    content: 'Recebido API: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .enviado-central-badge::before {
    content: 'Enviado Central: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .recebido-central-badge::before {
    content: 'Recebido Central: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .enviado-dispositivo-badge::before {
    content: 'Enviado Dispositivo: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .retorno-dispositivo-badge::before {
    content: 'Retorno Dispositivo: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .state-alterado-api-badge::before {
    content: 'State Alterado API: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .conexaoCentral-badge::before {
    content: 'Conexão Central: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }
  .conexaoWifi-badge::before {
    content: 'Conexão Wifi: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .brand-badge::before {
    content: 'Marca: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .delayOn-badge::before {
    content: 'Delay ao ligar: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .delayCmd-badge::before {
    content: 'Delay comando: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .otaType-badge::before {
    content: 'Tipo OTA: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .model-badge::before {
    content: 'Modelo: ';
    width: 80%;
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
    overflow-wrap: break-word;
  }

  .channels-badge::before {
    content: 'Canais: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .city-badge::before {
    content: 'Cidade: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .data-badge::before {
    content: 'Data: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .inicio-badge::before {
    content: 'Data início: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .data-final-badge::before {
    content: 'Data final: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .hora-badge::before {
    content: 'Hora: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .dias-badge::before {
    content: 'Dias: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .uf-badge::before {
    content: 'Estado: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .erro-em-badge::before {
    content: 'Erro em: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .erro-badge::before {
    content: 'Erro: ';
    font-size: 10px;
    color: #666666;
    text-transform: none;
    padding-right: 5px;
  }

  .filterForm {
    width: 100%;
    padding: 0;
  }

  .log-tabs {
    margin-bottom: 20px;
  }

  .log-tabs div div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
